<template>
  <div class="all-goods">
    <Search></Search>
    <!-- 导航栏组件 -->
    <tab-nav :store_id="storeid"></tab-nav>
    <!-- 商品列表 -->
    <div class="g-list" v-loading="loading">
      <div class="g-content">
        <!-- 排序 -->
        <div class="c-sort">
          <div class="s-list clearfix">
            <div
              :class="sort == 1 ? 's-item s-current' : 's-item'"
              @click="goodssort(1)"
            >
              <span>综合排序</span>
            </div>
            <div
              :class="sort == 2 ? 's-item s-num s-current' : 's-item s-num'"
              @click="goodssort(2)"
            >
              <span class="i-txt">销量</span>
              <i class="fa fa-arrow-down"></i>
            </div>
            <div
              class="s-price"
              :class="
                sort == 3 && sorttype == 1
                  ? 's-itemUp'
                  : sort == 3 && sorttype == 2
                  ? 's-itemDown'
                  : ''
              "
              @click="goodssort(3)"
            >
              <span class="s-txt">价格</span>
              <i class="fa fa-caret-up s-up"></i>
              <i class="fa fa-caret-down s-down"></i>
            </div>
          </div>
        </div>
        <!-- 商品列表 -->
        <div class="l-main">
          <div
            class="item"
            v-for="(item, index) in goodslist"
            :key="index"
            @click="togoods(item.id)"
          >
            <div class="picture">
              <img :src="item.img" />
            </div>
            <div class="price">
              <span class="font-color">¥{{ item.price }}</span>
              <span class="y_money">¥{{ item.originalprice }}</span>
            </div>
            <div class="i-name">
              <a>{{ item.goods_name }}</a>
            </div>
            <div class="i-footer">
              <div class="sales">
                成交量:&nbsp;<span>{{ item.vbep }}笔</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 页码 -->
        <div class="l-footer">
          <div class="f-prev f-item" @click="sgoodslist(page - 1)">
            <i class="fa fa-angle-left"></i>
            <span>上一页</span>
          </div>
          <div class="f-list">
            <li
              class="l-item"
              :class="index == page ? 'l-current' : ''"
              v-for="(index, key) in Math.ceil(count / limit)"
              :key="key"
              @click="sgoodslist(index)"
            >
              {{ index }}
            </li>
          </div>
          <div class="f-next f-item" @click="sgoodslist(page + 1)">
            <span>下一页</span>
            <i class="fa fa-angle-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Search from '../../components/search.vue'
  import tabNav from '../../components/tabNav.vue'
  import { StoreGoodsList } from '@/api/index'
  export default {
    name: "allGoodsList",
    components: { tabNav,Search },
    data() {
      return {
        id: '',
        storeid: '',
        goodslist: [],
        page: 0,
        limit: 30,
        count: 0,
        sort: 1, // 排序 1为综合 2为销量 3为价格
        sorttype: 1, // 排序方式 1 为从低到高 2为从高到底
        loading:false,
      }
    },
    methods: {
      goodssort(sort) { // 排序点击
        this.sort = sort
        if (sort == 3) this.sorttype = this.sorttype == 1 ? 2 : 1;
        else this.sorttype = 0;
        this.sgoodslist(1);
      },
      async sgoodslist(page) { // 分类初始化函数
        if (page == 0) return false;
        if (page > this.count + 1) return false;
        this.loading = true;
        this.page = page;
        const { data: res } = await StoreGoodsList({page:page,storeid:this.storeid,id:this.id,limit:this.limit,sort:this.sort,sorttype:this.sorttype});
        this.goodslist = res.result.list
        this.count = res.result.count;
        this.loading = false;
      },
      togoods(id) {
        let routeUrl = this.$router.resolve({
          path: "/store/goods",
          query: { id: id }
        });
        window.open(routeUrl.href, '_blank');
      }
    },
    created() {
      this.id = this.$route.query.id;
      var storeid = this.$route.query.storeid;
      if (typeof this.id !== 'number' && isNaN(this.id)) { this.$router.push("/home/error"); return false; }
      if (typeof storeid !== 'number' && isNaN(storeid)) { this.$router.push("/home/error"); return false; }
      this.storeid = parseInt(storeid);
      this.sgoodslist(1);
    }
  }
</script>

<style lang="less" scoped>
.all-goods {
  padding-bottom: 20px;

  .g-list {
    width: 1200px;
    margin: 0 auto;

    .g-content {
      margin: 10px 0px;

      .c-sort {
        height: 30px;
        color: #666;
        background: #f2f2f2;
        display: flex;
        align-items: center;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        padding: 0px 10px;

        .s-list {
          padding: 5px 0px;

          .s-item {
            min-width: 60px;
            height: 20px;
            float: left;
            line-height: 20px;
            text-align: center;
            border: 1px solid #ddd;
            background: #fff;
            cursor: pointer;

            &:hover {
              color: #e1251b;
              border: 1px solid #e1251b;
            }
            .i-txt {
              margin-right: 5px;
            }
          }

          .s-price {
            min-width: 60px;
            height: 20px;
            float: left;
            position: relative;
            border: 1px solid #ddd;
            background: #fff;
            cursor: pointer;
            line-height: 20px;
            text-align: center;

            &:hover {
              .s-txt {
                color: #e1251b;
              }

              border: 1px solid #e1251b;
            }

            .s-up {
              position: absolute;
              top: 1px;
              right: 6px;
            }

            .s-down {
              position: absolute;
              top: 9px;
              right: 6px;
            }
          }

          .s-current {
            border: 1px solid #e1251b;
            background: #e1251b;
            color: #fff;

            &:hover {
              border: 1px solid #e1251b;
              background: #e1251b;
              color: #fff;
            }
          }

          .s-itemUp {
            cursor: pointer;
            border: 1px solid #e1251b;

            .s-txt {
              color: #fff;
            }

            background: #e1251b;

            .s-up {
              color: #fff;
            }

            &:hover {
              .s-txt {
                color: #fff;
              }

              border: 1px solid #e1251b;
            }
          }

          .s-itemDown {
            cursor: pointer;
            border: 1px solid #e1251b;

            .s-txt {
              color: #fff;
            }

            background: #e1251b;

            .s-down {
              color: #fff;
            }

            &:hover {
              .s-txt {
                color: #fff;
              }

              border: 1px solid #e1251b;
            }
          }

          .s-num {
            border-left: 1px solid transparent;
            border-right: 1px solid transparent;
          }
        }
      }

      .l-main {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;

        .item {
          width: 240px;
          height: 320px;
          box-sizing: border-box;
          cursor: pointer;
          margin-bottom: 20px;
          padding: 10px;
          background: #fff;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          border: 1px solid transparent;

          &:hover {
            border: 1px solid #ddd;
          }

          .picture {
            width: 200px;
            height: 192px;
            margin: 0 auto;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .price {
            margin-top: 10px;

            .font-color {
              font-size: 22px;
              font-weight: bold;
              color: #e1251b;
            }

            .y_money {
              color: #aaaaaa;
              font-size: 12px;
              text-decoration: line-through;
              margin-left: 8px;
            }
          }

          .i-name {
            overflow: hidden; //超出文本隐藏
            text-overflow: ellipsis; ///超出部分省略号显示
            display: -webkit-box; //弹性盒模型
            -webkit-box-orient: vertical; //上下垂直
            -webkit-line-clamp: 2; //自定义行数
            color: #333;
            margin: 10px 0px;
          }

          .i-footer {
            .sales {
              color: #666;

              span {
                color: #b57c5b;
              }
            }
          }
        }
      }

      .l-footer {
        height: 38px;
        display: flex;
        justify-content: flex-end;
        margin: 30px 0px;

        .f-list {
          .l-item {
            width: 38px;
            height: 38px;
            border: 1px solid #707070;
            float: left;
            margin-left: 5px;
            line-height: 38px;
            text-align: center;
            background: #f7f7f7;
            font-size: 14px;
            cursor: pointer;
          }

          .l-current {
            background: none;
            border: none;
            color: #e1251b;
          }
        }

        .f-item {
          width: 85px;
          height: 38px;
          border: 1px solid #707070;
          font-size: 14px;
          line-height: 38px;
          text-align: center;
          position: relative;
          background: #f7f7f7;
          cursor: pointer;
        }

        .f-prev {
          i {
            font-size: 22px;
            position: absolute;
            top: 7px;
            left: 8px;
            color: #aaa;
          }
        }

        .f-next {
          margin-left: 5px;

          i {
            font-size: 22px;
            position: absolute;
            top: 7px;
            right: 8px;
            color: #aaa;
          }
        }

        .f-num {
          height: 38px;
          display: flex;
          line-height: 38px;
          margin-left: 20px;

          .n-pages {
            font-size: 14px;

            strong {
              color: #666;
            }
          }

          .f-page {
            display: flex;
            font-size: 14px;

            .p-num {
              box-sizing: border-box;
              width: 38px;
              height: 38px;
              text-align: center;
              font-size: 14px;
              text-align: center;
            }
          }

          .f-confirm {
            width: 38px;
            height: 38px;
            background: #f7f7f7;
            border: 1px solid #707070;
            font-size: 14px;
            text-align: center;
            line-height: 38px;
            color: #666;
          }
        }
      }
    }
  }
}
</style>