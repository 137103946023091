<template>
  <div class="tab-nav">
    <!--头部热销图片展示 -->
    <div class="g-hd">
      <!-- logo -->
      <div class="c-content">
        <div class="c-logo">
          <a :href="'/store/home?id=' + store_id">
            <img :src="logo" />
          </a>
        </div>
        <div class="l-list">
          <li class="l-item">
            <a :href="headimg[0].url" target="_blank">
              <img :src="headimg[0].img" />
            </a>
          </li>
          <li class="l-item">
            <a :href="headimg[1].url" target="_blank">
              <img :src="headimg[1].img" />
            </a>
          </li>
          <li class="l-item">
            <a :href="headimg[2].url" target="_blank">
              <img :src="headimg[2].img" />
            </a>
          </li>
          <li class="l-item">
            <a :href="headimg[3].url" target="_blank">
              <img :src="headimg[3].img" />
            </a>
          </li>
        </div>
      </div>
    </div>
    <!--菜单导航 -->
    <div class="g-nav">
      <ul class="n-content clearfix">
        <li class="n-item">
          <a :href="'/store/home?id=' + store_id">首页</a>
        </li>
        <li class="n-item" v-for="(item, index) in classify" :key="index">
          <a
            :href="
              '/store/all/list?storeid=' + item.store_id + '&id=' + item.id
            "
            >{{ item.name }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { StoreHead } from '@/api/index'
export default {
  name: "tabNav",
  data(){
    return{
      id:0,
      classify:[],
      logo:'',
      headimg:[{url:'',img:''},{url:'',img:''},{url:'',img:''},{url:'',img:''}],
    }
  },
  props: {
    store_id: {
      type:Number  
    }
  },
  watch:{
    store_id:function(newValue,oldValue){
      this.getHead(newValue);
    },
  },
  methods: {
    async getHead(store_id){
      if (typeof store_id != 'number' || isNaN(store_id) || store_id<1) return false;
      const { data: res } = await StoreHead({id:store_id});
      this.classify = res.result.clist
      if(res.result.image.length>0){
        this.logo    = res.result.logo
        this.headimg = res.result.image
      }
    }
  },
  created() {
    this.getHead(this.store_id)
  }
}
</script>

<style lang="less" scoped>
.tab-nav {
  background: #fff;
  .g-hd {
    width: 1200px;
    margin: 0 auto;

    .c-content {
      padding: 10px 0px;
      padding: 5px 0px;
      vertical-align: middle;

      .c-logo {
        display: inline-block;
        vertical-align: middle;
        img {
          width: 300px;
          display: block;
        }
      }
      .l-list {
        display: inline-block;
        vertical-align: middle;
        .l-item {
          display: inline-block;
          margin-left: 5px;
          img {
            width: 220px;
            vertical-align: middle;
          }
        }
      }
    }
  }
  .g-nav {
    background: #333;
    .n-content {
      width: 1200px;
      margin: 0 auto;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 14px;

      .n-item {
        width: 100px;
        float: left;

        a {
          color: #fff;
          height: 40px;
        }

        &:hover {
          background: #e1251b;
        }
      }
    }
  }
}
</style>